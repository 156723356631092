var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"page",attrs:{"fluid":""}},[(_vm.pending)?_c('AccountPendingAlert'):_vm._e(),(_vm.error)?_c('v-alert',{staticClass:"alert-error",attrs:{"text":"","elevation":"0","outlined":"","prominent":"","rounded":"","type":"error","icon":"mdi-cloud-alert"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('v-card',{staticClass:"card-display"},[_c('v-card-title',{staticClass:"header"},[_vm._v("Upload New Composition")]),_c('v-form',{ref:"uploadForm",staticClass:"upload",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-col',{attrs:{"cols":"12"},on:{"drop":function($event){$event.preventDefault();return _vm.dropScoreFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"text-center"},[_c('v-file-input',{staticClass:"file-upload",attrs:{"rules":_vm.scoreFileValidation,"label":"Score Upload (PDF)","color":"secondary","placeholder":"Score Upload (PDF)","prepend-icon":"","append-icon":"mdi-file-pdf-box","show-size":1000,"persistent-hint":true,"counter-size-string":"300 MB Limit","counter":"","outlined":"","filled":"","required":"","disabled":_vm.uploadingFiles,"elevation":"0","accept":"application/pdf"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"label":"","color":_vm.validSize(_vm.scoreFile.size) ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.scoreFile),callback:function ($$v) {_vm.scoreFile=$$v},expression:"scoreFile"}})],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"},on:{"drop":function($event){$event.preventDefault();return _vm.handleMusicFiles($event.dataTransfer.files)},"dragover":function($event){$event.preventDefault();}}},[_c('v-file-input',{staticClass:"file-upload",attrs:{"value":_vm.musicFiles,"counter":"","rules":_vm.musicFilesValidation,"label":"Sheet Music Upload (PDFs)","color":"secondary","multiple":"","placeholder":"Sheet Music Upload (PDFs)","prepend-icon":"","append-icon":"mdi-file-pdf-box","outlined":"","show-size":1000,"filled":"","hint":'Optional',"persistent-hint":true,"disabled":_vm.uploadingFiles,"accept":"application/pdf","counter-size-string":"300 MB Limit Per File"},on:{"change":_vm.handleMusicFiles,"click:clear":function($event){_vm.musicFiles = []}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
var index = ref.index;
return [_c('v-chip',{attrs:{"label":"","color":_vm.validSize(_vm.musicFiles[index].size) ? 'primary' : 'error',"close":"","close-icon":"mdi-close-box"},on:{"click:close":function($event){return _vm.deleteMusicFileItem(_vm.musicFiles[index])}}},[_vm._v(" "+_vm._s(text)+" ")])]}}])})],1)],1),_c('v-row',{staticClass:"text-center third-row-container"},[_c('v-col',{attrs:{"cols":"6"},on:{"drop":function($event){$event.preventDefault();return _vm.dropCoverFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('v-file-input',{staticClass:"file-upload",attrs:{"counter":"","rules":_vm.coverPageValidation,"label":"Web Thumbnail (JPG)","color":"secondary","placeholder":"Web Thumbnail (JPG)","prepend-icon":"","append-icon":"mdi-image-plus","outlined":"","show-size":1000,"filled":"","counter-size-string":"300 MB Limit","disabled":_vm.uploadingFiles,"hint":'Optional',"persistent-hint":true,"accept":"image/jpeg"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"label":"","color":_vm.validSize(_vm.coverPage.size) ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.coverPage),callback:function ($$v) {_vm.coverPage=$$v},expression:"coverPage"}})],1),_c('v-col',{attrs:{"cols":"6"},on:{"drop":function($event){$event.preventDefault();return _vm.dropAudioFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('v-file-input',{staticClass:"file-upload",attrs:{"counter":"","rules":_vm.previewAudioValidation,"label":"Preview Audio (MP3)","color":"secondary","placeholder":"Preview Audio (MP3)","prepend-icon":"","append-icon":"mdi-music","outlined":"","counter-size-string":"300 MB Limit","show-size":1000,"filled":"","disabled":_vm.uploadingFiles,"hint":'Optional',"persistent-hint":true,"accept":"audio/mpeg"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"label":"","color":_vm.validSize(_vm.previewAudio.size) ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.previewAudio),callback:function ($$v) {_vm.previewAudio=$$v},expression:"previewAudio"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"wrapper",attrs:{"cols":"12"},on:{"drop":function($event){$event.preventDefault();return _vm.dropWrapperFile.apply(null, arguments)},"dragover":function($event){$event.preventDefault();}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("A wrapper is a protective cardstock cover or jacket that wraps arround the Score and Parts.")])]),_c('v-file-input',{staticClass:"file-upload",attrs:{"counter":"","rules":_vm.wrapperFileValidation,"label":"Wrapper/Folio (PDF)","color":"secondary","placeholder":"Wrapper/Folio (PDF)","prepend-icon":"","append-icon":"mdi-file-pdf-box","outlined":"","show-size":1000,"filled":"","disabled":_vm.uploadingFiles,"counter-size-string":"300 MB Limit","hint":'Optional',"persistent-hint":true,"accept":"application/pdf"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"label":"","color":_vm.validSize(_vm.wrapperFile.size) ? 'primary' : 'error'}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.wrapperFile),callback:function ($$v) {_vm.wrapperFile=$$v},expression:"wrapperFile"}})],1)],1),_c('v-row',{staticClass:"d-flex align-center justify-center"},[_c('v-col',{staticStyle:{"max-width":"max-content"},attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"px-14",attrs:{"type":"submit","color":"lightGold","dark":"","elevation":"0","rounded":"","loading":_vm.uploadingFiles}},[_vm._v(" Upload ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }